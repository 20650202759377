export default `
@page {
  size: landscape;
  margin: 8mm;
}
body {
    font-family: DejaVu Sans;
    padding: 0;
    margin-left: -10px;
    margin-top: -30px;
}
.center {
    text-align: center;
    font-size: 12px;
}
.center .logo {
    position: absolute;
    top: -35px;
    height: 130px;
    margin-left: 20px;

}
.center .logo img {
    display: block;
    height: 90px;
    width: 60px;
    object-fit: cover;
    margin-top: 30px;
}
.center .title {
  margin: auto;
  font-weight: bold;
  position: relative;
  top: 30px;
}
table {
    font-size: 10px;
    position: relative;
    bottom: 10px;
}
.center .content {
    margin-left: 10px;
    width: 1075px;
    height: 685px;
    margin-top: 40px;
}
.content {
    display: flex;
    justify-content: center;
}
.table {
    width: 100%;
}
.table td, th {
    max-width: 12.5%;
    min-width: 12.5%;
}
table th {
    text-align: left;
    max-width: 12.5%;
    min-width: 12.5%;
}
table td {
    overflow-wrap: break-word;
    max-width: 12.5%;
    min-width: 12.5%;
    text-align: center;
}
table tr {
    overflow-wrap: break-word;
    margin: 0px;
    padding: 0px;
}
table tr div {
    margin: 0px;
    padding: 0px;
}
table td {
    margin: 0px;
    padding: 0px;
}
.center .content .main {
    height: 100px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}
.center .content .main div {
    float: left;
    width: 333px;
    margin: 10px;
    text-align: left;
}
table, td, th{
    border: 1px solid black;
    border-collapse: collapse;
}
.mb-2{
    margin-bottom: 0.6249894rem !important;
}
.td-hour {
    min-width: 80px;
    font-weight: bold;
}
.td-header {
    font-weight: bold;
    margin: 0px;
    padding: 0px;
}
tr {
    margin-bottom: 0px;
    padding-bottom: 0px;
    padding: 0px;
    height: 16px;
}
td {
    margin: 0px;
    padding: 0px;
    line-height: 12px;
}
td div {
    margin: 0px;
    padding: 0px;
    line-height: 12px;
}
h4 {
    line-height: 10px;
}
`;
