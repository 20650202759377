<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12" md="6">
                    <b-form-group :label="$t('classroom')">
                        <div class="label-as-input">
                            {{ form.classroom ? form.classroom.name : '-' }}
                        </div>
                    </b-form-group>
                    <b-form-group :label="$t('date')">
                        <div class="label-as-input">
                            {{ form.date ? toLocaleDate(form.date) : '-' }}
                        </div>
                    </b-form-group>
                    <b-form-group :label="$t('start_time')+' / '+$t('end_time')">
                        <div class="label-as-input">
                            {{ form.start_time ? form.start_time : '-' }} / {{ form.end_time ? form.end_time : '-' }}
                        </div>
                    </b-form-group>
                    <b-form-group :label="$t('repeat')">
                        <div class="label-as-input">
                            {{ form.repeat_type ? $t('yes') : $t('no') }}
                        </div>
                    </b-form-group>
                    <b-row v-if="form.repeat_type">
                        <b-col>
                            <b-form-group :label="$t('repeat_type')">
                                <div class="label-as-input">
                                    {{ form.repeat_type ? form.repeat_type_text : '-' }}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group :label="$t('repeat_count')">
                                <div class="label-as-input">
                                    {{ getObjectValue(form, 'repeat_count') }}
                                </div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="type" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('event_type')">
                            <parameter-selectbox v-model="formData.type"
                                                 code="reservation_types"
                                                 :validate-error="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="instructor_id" v-slot="{valid, errors}">
                        <b-form-group :label="$t('event_owner')">
                            <staff-auto-complete v-model="formData.instructor_id"
                                                 code="reservation_types"
                                                 return-value="id"
                                                 :validate-error="errors[0]"
                                                 :valueObject="instructor"
                            />
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="explanation" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('explanation')">
                            <b-form-textarea no-resize
                                             rows="4"
                                             v-model="formData.explanation"
                                             :state="errors[0] ? false : null"
                            />
                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>

            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary"
                          @click="updateForm"
                          :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Components
    import ClassroomSelectbox from '@/components/interactive-fields/ClassroomSelectbox'
    import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
    import SelectDate from '@/components/interactive-fields/SelectDate';
    import StaffAutoComplete from '@/components/interactive-fields/StaffAutoComplete';
    import TimePicker from '@/components/elements/TimePicker';
    import TrueFalseSelectbox from '@/components/interactive-fields/TrueFalseSelectbox';

    // Services
    import ReservationService from '@/services/ReservationService';

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,

            ClassroomSelectbox,
            ParameterSelectbox,
            SelectDate,
            StaffAutoComplete,
            TimePicker,
            TrueFalseSelectbox
        },
        props: {
            formId: {
                type: Number
            }
        },
        data() {
            return {
                formLoading: false,
                form: {},
                formData: {
                    type: null,
                    instructor_id: null,
                    explanation: null
                },
                instructor: null
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                ReservationService.get(id)
                                  .then(response => {
                                      let data = response.data.data;
                                      this.form = data

                                      this.formData = {
                                          type: data.type,
                                          instructor_id: data.instructor_id,
                                          explanation: data.explanation
                                      }

                                      this.instructor = {
                                          value: data.instructor_id,
                                          text: data.instructor_name
                                      }
                                  })
                                  .catch(e => {
                                      this.showErrors(e)
                                  })
            },
            async updateForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.formId) {
                    this.formLoading = true;

                    ReservationService.update(this.formId, this.formData)
                                      .then(response => {
                                          this.$emit('updateFormSuccess', true);
                                          this.$toast.success(this.$t('api.' + response.data.message));
                                      })
                                      .catch(e => {
                                          this.showErrors(e, this.$refs.formModalValidate);
                                      })
                                      .finally(() => {
                                          this.formLoading = false
                                      })
                }
            }
        }
    }
</script>
